import { FC, Fragment } from 'react';
import { BoxOverflow, IconButton } from 'styles';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Dialog from 'styles/dialog/Dialog';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const DialogConfirm: FC = () => {
	const {
		open,
		title,
		content,
		labelCancel,
		labelConfirm,
		variantButtonCancel,
		multi,
		data,
		buttonClose,
	} = useSelector((store: RootState) => store.hooks.dialog);
	const dialogConfirm = useDialogConfirm();
	const { type } = useSelector((store: RootState) => store.hooks.dialog);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<Dialog
			type={type}
			open={open}
			close={
				buttonClose ? (
					<IconButton onClick={() => dialogConfirm.close()}>
						<CloseDialog />
					</IconButton>
				) : null
			}
			variant="confirm"
			title={tClientLabels(title)}
			events={!!labelCancel || !!labelConfirm}
			actions={
				<Fragment>
					{labelCancel ? (
						<Button
							variant={variantButtonCancel}
							// onClick={() => dialogConfirm.cancel()}
						>
							{tClientLabels(labelCancel)}
						</Button>
					) : null}
					{labelConfirm ? (
						<Button variant="contained" onClick={() => dialogConfirm.confirm()}>
							{tClientLabels(labelConfirm)}
						</Button>
					) : null}
				</Fragment>
			}
		>
			{!multi ? (
				<Typography variant="body">{tClientLabels(content)}</Typography>
			) : (
				<BoxOverflow variant="y" sx={{ gap: '20px' }}>
					{data.map((e, index) => (
						<Stack key={index} gap={'16px'}>
							<Typography variant="title6">{tClientLabels(e.title)}</Typography>
							<Typography variant="body">{tClientLabels(e.content)}</Typography>
						</Stack>
					))}
				</BoxOverflow>
			)}
		</Dialog>
	);
};

export default DialogConfirm;
